<template>
    <div>
        <NavBarAuth />
        <div class="bg-movie" :style="{ backgroundImage: 'url(' + getPoster(movie.backdrop_path) + ')' }" v-if="movie">
        </div>
        <b-breadcrumb>
        <b-breadcrumb-item to="/popularmovies">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        </b-breadcrumb-item>
        <b-breadcrumb-item to="/movielist">Mi lista</b-breadcrumb-item>
        <b-breadcrumb-item active>{{movie.title}}</b-breadcrumb-item>
        </b-breadcrumb>
        <b-container>
            <b-row>
                <b-col cols="12" md="5" class="movie-description">
                    <h1>{{movie.title}}</h1><p>{{movieYear(movie.release_date)}}</p>
                    <p>{{movie.overview}}</p>
                    <div class="d-flex">
                        <p class="mr-2">{{movie.vote_average}} / 10</p>
                        <b-icon-star-fill variant="warning"></b-icon-star-fill>
                    </div>
                    <b-button @click="list">Volver <b-icon-arrow-left-circle></b-icon-arrow-left-circle></b-button>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import {mapState} from 'vuex'
import NavBarAuth from "@/components/NavBarAuth.vue";

export default{
    name: "MovieInfoView",
    components: {
    NavBarAuth,
  },
    methods:{
        ...mapActions(['getMovie']),
        getPoster(backdrop_path){
            return `https://image.tmdb.org/t/p/original${backdrop_path}`
        },
        movieYear(date){
            return String(date).substring(0, 4) 
        },
         list(){
        this.$router.push("/movielist")
      }
        
    },
    created(){
        this.getMovie(this.getMovieId)
        document.body.classList.add('no-bg')
    },
    computed: {
        getMovieId(){
            const id = this.$route.params.id;
            return id
        },
        
      ...mapState(['movie'])
    },
    destroyed(){
        document.body.classList.remove('no-bg')
    },
}


</script>

<style scoped lang="scss">
.bg-movie{
    min-height: 100vh;
    opacity: 0.2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    background-position: center;
}
.movie-description{
    margin-top: 15vh;
}
</style>
