<template>
  <div>
    <b-nav-form>
      <b-form-input
        size="sm"
        class="mr-sm-2"
        placeholder="Ingresa una película"
        v-model="movieid"
      >
      </b-form-input>
      <b-button
        size="sm"
        class="search my-2 my-sm-0"
        type="button"
        @click="getMovieSearch([movieid])"
        to="/search"
      >
        <b-icon-search></b-icon-search>
      </b-button>
    </b-nav-form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      movieid: "",
    };
  },
  name: "SearchBar",
  methods: {
    ...mapActions(["getMovieSearch"]),
  },
};
</script>

<style scoped lang="scss">
.search {
  border: none !important;
  color: #c3ffce;
}
@media (max-width: 768px) {
  .form-inline {
    display: flex;
    flex-flow: inherit !important;
    width: 100%;
  }
}
</style>
