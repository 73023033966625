<template>
  <b-container class="mt-4">
    <h1 class="mb-5">Resultado de la Busqueda</h1>
    <b-row>
      <b-col
        cols="auto"
        md="3"
        v-for="(movies, index) in MovieSearch"
        :key="index"
      >
        <b-card
          :title="movies.title"
          :img-src="getImage(movies.poster_path)"
          img-alt="movie.title"
          img-top
          tag="article"
          class="mb-4 mx-2"
        >
          <div class="d-flex">
            <p class="mr-2">{{ movies.vote_average }} / 10</p>
            <b-icon-star-fill variant="warning"></b-icon-star-fill>
          </div>
          <b-button>
            <router-link :to="{ path: '/movieinfo/' + movies.id }">
              Ver más detalles
            </router-link>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapState } from "vuex";

export default {
  name: "SearchList",
  data() {
    return {};
  },
  methods: {
    ...mapGetters(["getSearchIdMovie"]),
    getImage(image) {
      return `https://image.tmdb.org/t/p/original${image}`;
    },
    ...mapActions(["getMovieSearch"]),
  },

  mounted() {
    this.getMovieSearch();
  },
  computed: {
    ...mapState(["MovieSearch"]),
  },
};
</script>

<style scoped>
</style>