<template>
  <div class="home">
  <b-breadcrumb>
    <b-breadcrumb-item to="/">
    <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
    </b-breadcrumb-item>
    <b-breadcrumb-item active to="/upcomingmovies">Próximos Estrenos</b-breadcrumb-item>
  </b-breadcrumb>
  <UpcomingList />
  </div>
</template>

<script>
// @ is an alias to /src
import UpcomingList from "@/components/UpcomingList.vue";
export default {
  name: 'UpcomingMovies',
  components: {
    UpcomingList
  },
  methods:{
  },
  mounted() {
  }
}
</script>
