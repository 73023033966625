<template>
  <footer class="site-footer container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-sm-6 col-xs-12">
          <p class="copyright-text">
            Copyright &copy; 2022 All Rights Reserved by
            <a href="#">PELICOOLAPP</a>.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterView",
};
</script>

<style>
.site-footer {
  margin-top: 35px;
  background-color: #26272b;
  padding: 25px 25px;
  font-size: 15px;
  line-height: 24px;
  color: #737373; 
  position: absolute;
  left: 0;
  width: 100vw;
}

.copyright-text {
  margin: 0;
  text-align: right;
}
@media (max-width: 991px) {
  .copyright-text {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .copyright-text {
    text-align: center;
  }
}
</style>