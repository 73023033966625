<template>
  <div>
    <NavBar />
    <div class="video">
      <video
        src="../assets/video/hd0983_preview.mp4"
        autoplay
        loop
        muted
      ></video>
      <h1>PELICOOLAPP</h1>
    </div>
    <b-container>
      <div class="text-center py-5">
        <h2 class="title">
          Hazle seguimiento a lo que ves<br />
          <strong>Descubre</strong> nuevas películas
        </h2>
      </div>
      <b-row class="justify-content-center mb-5">
        <b-col cols="auto" md="4">
          <img
            src="../assets/popcorn-home.svg"
            height="400px"
            alt="popcorn-home"
            class="img-fluid popcorn-home"
          />
        </b-col>
        <b-col cols="auto" md="5">
          <p class="pt-5 register-text">
            <strong>¿No sabes que ver este fin de semana?</strong> <br />
            Revisa lo que está viendo el resto, guíate por puntuación y sigue el
            contenido más relevante
          </p>
          <b-button to="/register">¡Regístrate!</b-button>
        </b-col>
      </b-row>
      <PopularHome />
      <hr />
      <UpcomingHome />
    </b-container>
  </div>
</template>
 <script>
import PopularHome from "../components/PopularHome.vue";
import UpcomingHome from "../components/UpcomingHome.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "HomeView",
  components: {
    PopularHome,
    UpcomingHome,
    NavBar,
  },
};
</script>
    
 <style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Nosifer&display=swap");

.video {
  height: 50vh;
  overflow: hidden;
  position: relative;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
  }
  h1 {
    font-family: "Nosifer", cursive !important;
    font-size: 10vw;
    font-weight: 800;
    mix-blend-mode: multiply;
    background: #000000;
    text-align: center;
    height: 100%;
    line-height: 50vh;
  }
}
.title {
  font-weight: 300;
}

.register-text {
  font-weight: 300;
  font-size: 22px;
}
.popcorn-home {
  width: 100%;
}

strong {
  font-weight: 700;
}
</style>