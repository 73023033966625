<template>
  <div id="app">    
    <router-view/>
    <FooterView/>    
  </div>
</template>
<script>

/* firabase */
import { getFirestore } from 'firebase/firestore'
import { mapActions } from 'vuex'
import FooterView from "@/components/FooterView.vue";


export default {
  name:'App',
    components: {       
        FooterView       
    },
     

  methods:{
    ...mapActions(["instanceFirestore", "getMovieList" , "getNick"]),
    initFirestore() {
      this.instanceFirestore(getFirestore);
    },

  },
  created(){
    this.initFirestore();
  },
  beforeMount() {
    this.getMovieList();
    this.getNick();
    
  },
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

hr{
  height: 1px;
  background-color:#e3d7fb;
  border: none;
}

body{
  background-color: #212121 !important;
  background-image: url(assets/bg-icons.svg);
  background-size: 100%;
  background-attachment: fixed;
}
body.no-bg{
    background-image: none !important;
}

.btn-secondary{
  background-color: transparent !important;
  border: 1px solid #c3ffce !important;
  color: #c3ffce !important;
}

.btn-secondary:hover{
  background-color: #c3ffce !important;
  border: 1px solid #c3ffce !important;
  color: #2f2f2f !important;
}

.dropdown-menu{
  background-color: #2f2f2f !important;

} 

#modal-profile{
  color: #e3d7fb !important;
}

.modal-content{
  background-color: #2f2f2f !important; 
}

.modal-footer .btn-primary{
  background-color: #c3ffce;
  border: 1px solid #c3ffce;
  color: #2f2f2f;
  &:hover{
    background-color: #94bc91;
    border: 1px solid #94bc91;
  }
}

.dropdown-item{
  color:#fff !important;
  &:hover{
    background-color: #c3ffce !important;
    color:#2f2f2f !important;
  }
  &:active{
    background-color: #94bc91 !important;
  }
}

.close{
  color: #e3d7fb !important;
}

.breadcrumb{
  background-color: transparent !important;
}

.breadcrumb-item a{
  color:#c3ffce;
  &:hover{
    color:#94bc91;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
    color: #c3ffce !important;
    content: "▸" !important;
}

.breadcrumb-item.active {
    color: #e3d7fb !important;
}

.card{
  border: none !important;
  background-color: #2f2f2f !important;
}

.card-body{
  background-color: #2f2f2f;
  p{
    font-size: 0.8em;
  }
}

.card-title{
  font-size: 1.1em;
}

a {
  color: #c3ffce !important;
  text-decoration: inherit;
  &:hover {
    color: #2f2f2f !important;
    text-decoration: inherit;
  }  
}
</style>
