<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info" class="my-navbar">
      <b-navbar-brand to="/">
        <img src="../assets/pelicoolapp-logo.svg" height="50px" alt="logo" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- menu mobile -->
        <b-navbar class="d-md-none d-sm-block navbar-mobile">
          <b-nav-item @click="login">Login</b-nav-item>
          <b-nav-item @click="register">Registrate</b-nav-item>
        </b-navbar>
        <!-- menu desktop -->
        <b-navbar-nav class="ml-auto d-none d-sm-flex">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b-avatar variant="info"></b-avatar>
              <b-badge></b-badge>
            </template>
            <b-dropdown-item @click="login">Login</b-dropdown-item>
            <b-dropdown-item @click="register">Registrate</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "NavBar",

  methods: {
    exit() {
      this.$router.push("/");
    },
    login() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
  },
};
</script>

<style scoped lang="scss">
.my-navbar {
  background-color: #2f2f2f !important;
}

.navbar-nav {
  align-items: center;
}

@media (max-width: 768px) {
  .navbar-mobile {
    display: block !important;
    list-style: none;
    .form-inline {
      display: flex;
      flex-flow: inherit !important;
      width: 100%;
    }
    .nav-link {
      color: #ffffff;
    }
  }
}
</style>
