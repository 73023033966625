<template>
  <div>
    <NavBarAuth />
    <b-breadcrumb>
      <b-breadcrumb-item to="/popularmovies">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item active to="/profile">Editar Perfil</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container class="d-flex justify-content-center" v-for="(nick,index) in listNick" :key="index">
      <b-card style="max-width: 25rem" class="mb-2 my-5" v-if=" nick.mailuser == activeUser.email" >
        <div class="text-center" >
          <b-avatar
            class="mt-5 mb-3"
            src="https://placekitten.com/300/300"
            size="6rem"
          ></b-avatar>
          <h1>Hola {{nick.nick_user}} </h1>
          <div class="d-flex">
            <p class="mr-2">Cambiar tu usuario</p>
            <b-icon-pencil-square
              class="edit"
              v-b-modal.modal-profile
            ></b-icon-pencil-square>
          </div>
        </div>
        <hr />
        <p>email: {{nick.mailuser}}</p>
        <!-- ver si se puede dejar como disabled, pero que traiga el mail del usuario registrado real -->
        <p>Edita tu lista</p>
        <p>Soporte</p>
        <p>Salir</p>
        <hr />
        <small>Versión 1.0</small>
      </b-card>
      <!-- modal -->
      <b-modal id="modal-profile" hide-footer title="Editar nombre de usuario" v-if=" nick.mailuser == activeUser.email" >
        <b-form-input
          v-model="text"
          placeholder="Ingresa tu nuevo nombre de usuario"
          ></b-form-input>
        <div class="mt-2"></div>  
        <b-button class="mt-2 mr-3" @click="closeModal(activateUpdateNick([nick.id,text]))" >Guardar</b-button>    
        <b-button class="mt-2" @click="closeModal">Cerrar</b-button>        
      </b-modal>
    </b-container>    
  </div>
</template>

<script>
import { mapGetters, mapState,mapActions } from "vuex";
import NavBarAuth from "@/components/NavBarAuth.vue";


export default {
  name: "ProfileView",
  components: {
    NavBarAuth,    
  },
  data() {
    return {
      text: "",
      
    };
  },
  computed: {
    ...mapGetters(["activeUser"]),
    ...mapState(["listNick"]),
  },
  methods:{
    closeModal(){
      this.$bvModal.hide("modal-profile")
    },
    ...mapActions(["activateUpdateNick"]),
   
  }
};
</script>

<style scoped lang="scss">
.edit {
  color: #c3ffce;
  &:active {
    background-color: #94bc91;
  }
}

small {
  color: #e3d7fb;
}
</style>
