<template>
  <div class="home">
    <NavBarAuth />
    <b-breadcrumb>
      <b-breadcrumb-item to="/popularmovies">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item active @click="popular"
        >Películas Populares</b-breadcrumb-item
      >
    </b-breadcrumb>
    <PopularList />  
  </div>
</template>

<script>
// @ is an alias to /src
import PopularList from "@/components/PopularList.vue";
import NavBarAuth from "@/components/NavBarAuth.vue";

// import { mapActions } from "vuex";

export default {
  name: "PopularMovies",
  components: {
    PopularList,
    NavBarAuth,    
  },
  methods: {
    // ...mapActions(["addPopularMovies"]),
    popular() {
      this.$router.push("/popularmovies");
    },
  },
  mounted() {
    // this.addPopularMovies()
  },
};
</script>
