<template>
  <div>
    <NavBar />
    <b-breadcrumb>
      <b-breadcrumb-item to="/">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item active to="/login">Login</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container class="d-flex justify-content-center">
      <b-card 
        style="max-width: 25rem" 
        class="mb-2 my-5 mx-2 text-center">
        <h1 class="my-4">Ingresa tus datos</h1>
        <div role="group">
          <label for="input-live"></label>
          <b-form-input
            id="input-live"
            v-model="user.email"
            :state="emailState"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Ingresa tu email"
            trim
          >
          </b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            Ingresa al menos 6 letras
          </b-form-invalid-feedback>
        </div>
        <div role="group">
          <label for="input-live"></label>
          <b-form-input
            id="input-live"
            v-model="user.password"
            :state="passwordState"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Ingresa tu password"
            trim
          >
          </b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            Ingresa al menos 8 letras
          </b-form-invalid-feedback>
        </div>
        <b-button class="my-4" @click="login">Ingresar</b-button>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
export default {
  name: "LoginView",
  components: {
    NavBar,
  },

  data() {
    return {
      email: "",
      password: "",
      user: {
        email: "",
        password: "",
      },
    };
  },

  computed: {
    emailState() {
      return this.user.email.length > 6 ? true : false;
    },

    passwordState() {
      return this.user.password.length > 7 ? true : false;
    },
  },
  methods: {
    async login() {
      try {
        const { email, password } = this.user;
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, email, password);
        this.$router.push("/popularmovies");
      } catch (error) {
        alert("Usuario o Contraseña Erronea Ingrese Nuevamente los Datos");
      }
    },
  },
};
</script>
<style scoped>
</style>