import Vue from 'vue'
import Vuex from 'vuex'
import { onSnapshot, collection, query, doc, deleteDoc, updateDoc } from 'firebase/firestore'
import { getAuth } from "firebase/auth";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    popularMovies: [],
    upcomingMovies: [],
    movie: '',
    MovieSearch: [],
    /*firebase */
    myFirestore: null,
    listMovie: [],
    listNick: [],
    listUsers: [],

  },
  getters: {
    activeUser() {
      let { currentUser } = getAuth()
      let user1 = { currentUser }
      if (user1.currentUser == undefined) {
        return undefined
      } else {
        return user1.currentUser
      }
    },
    getListMovieList(state) {
      return state.listMovie
    },
    getListUsers(state) {
      return state.listUsers
    },
  },
  mutations: {
    setPopularMovies: (state, data) => {
      state.popularMovies = data
    },
    setUpcomingMovies: (state, data) => {
      state.upcomingMovies = data
    },
    setMovie: (state, data) => {
      state.movie = data
    },
    setMovieSearch: (state, data) => {
      state.MovieSearch = data
    },
    /* Firebase */
    /* lista peliculas por ver*/
    instanceFirestore(state, fs) {
      state.myFirestore = fs;
    },
    mutateMovie(state, resultMovie) {
      // console.log("intente sacar la lista desde mutations")
      state.listMovieList = [...resultMovie];
    },
    /* lista nick */
    mutateNick(state, resultNick) {
      state.listNick = resultNick;
    },
    /** Remover de mi lista */
    removerList(state, payload) {
      let [param1] = payload;
      //console.log('param1 es el email: ' + param1);
      deleteDoc(doc(state.myFirestore, "movieapp", param1));
      //alert('Eliminado de su lista')

    },
    updateNick(state, payload) {
      let [param3, param4] = payload;
      console.log('id documento nick' + param3)
      console.log('nick a actualizar' + param4)
      const selecnick = doc(state.myFirestore, "nickuser", param3)
      updateDoc(selecnick, {
        nick_user: param4
      })
      // console.log('llegamos a la funcion')
    },
  },
  actions: {
    async getPopularMovies({ commit }) {
      const URL = `https://api.themoviedb.org/3/discover/movie?sort_by=popularity.desc&api_key=733f8294a8f9c6962c5acff96c96abfb`
      const data = await fetch(URL).then(response => response.json())
      commit('setPopularMovies', data.results)
    },
    async getUpcomingMovies({ commit }) {
      const URL = `https://api.themoviedb.org/3/movie/upcoming?api_key=733f8294a8f9c6962c5acff96c96abfb&language=en-US&page=1`
      const data = await fetch(URL).then(response => response.json())
      commit('setUpcomingMovies', data.results)
    },
    async getMovie({ commit }, id) {
      const URL = `https://api.themoviedb.org/3/movie/${id}?api_key=733f8294a8f9c6962c5acff96c96abfb`
      const data = await fetch(URL).then(response => response.json())
      commit('setMovie', data)
    },
    async getMovieSearch({ commit }, movieid) {
      //  console.log('tratando de sacar la id ' + movieid)
      const URL = `https://api.themoviedb.org/3/search/movie?api_key=733f8294a8f9c6962c5acff96c96abfb&query=${movieid}&page=1`
      //  console.log(URL)
      const data = await fetch(URL).then(response => response.json())
      //  console.log('lista de busqueda', data.results)
      commit('setMovieSearch', data.results)
    },
    /* firebase */
    async instanceFirestore({ commit }, fs) {
      commit("instanceFirestore", fs());
    },
    /* mostrar lista de peliculas por ver */
    async getMovieList({ commit }) {
      console.log("intente sacar la lista desde accion")
      if (this.state.myFirestore) {
        const q = query(
          collection(
            this.state.myFirestore,
            "movieapp" /* Nombre colección en Firebase */
          )
        );
        onSnapshot(q, (querySnapshot) => {
          const resultMovie = [];
          querySnapshot.forEach((doc) => {
            resultMovie.push({ id: doc.id, ...doc.data() }); // función propia de firebase

          });
          console.log(resultMovie)
          commit('mutateMovie', resultMovie);
        });
      }
    },

    activateRemoverList({ commit }, [param1]) {
      // console.log('Param1 = ' + param1 ) 
      // console.log('Param1 = ' + param2 )         
      commit('removerList', [param1])
    },

    activateUpdateNick({ commit }, [param3, param4, param5, param6]) {
      commit('updateNick', [param3, param4, param5, param6])
    },

    /* nick user */
    async getNick({ commit }) {
      if (this.state.myFirestore) {
        const q = query(
          collection(
            this.state.myFirestore,
            "nickuser" /* Nombre colección en Firebase */
          )
        );
        onSnapshot(q, (querySnapshot) => {
          const resultNick = [];
          querySnapshot.forEach((doc) => {
            resultNick.push({ id: doc.id, ...doc.data() }); // función propia de firebase
          });
          console.log(resultNick)
          commit('mutateNick', resultNick);
        });
      }
    },


  },
  modules: {
  },
  methods: {
  }
})
