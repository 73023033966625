<template>
  <b-container class="mt-4">
    <h1 class="mb-5 text-center">Peliculas más populares</h1>
    <b-row class="d-flex justify-content-center">
      <b-col
        cols="auto"
        class="col-lg-2 col-md-6"
        v-for="(movie, index) in popularMovies.slice(0, 5)"
        :key="index"
      >
        <img 
        :src="getImage(movie.poster_path)"
         class="img-fluid" />
      </b-col>
    </b-row>
    <div class="text-center my-4">
      <b-button 
      to="/login"
      >
      Ver todas
      </b-button>
    </div>
  </b-container>
</template>

<script>
import { mapActions } from "vuex";
import { mapState } from "vuex";

export default {
  name: "PopularHome",
  methods: {
    ...mapActions(["getPopularMovies"]),
    getImage(image) {
      return `https://image.tmdb.org/t/p/original${image}`;
    },
  },
  mounted() {
    this.getPopularMovies();
  },
  computed: {
    ...mapState(["popularMovies"]),
  },
};
</script>

<style scoped>
</style>
