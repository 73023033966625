<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info" class="my-navbar">
      <b-navbar-brand to="/popularmovies">
        <img src="../assets/pelicoolapp-logo.svg" height="50px" alt="logo" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- menu mobile -->
        <b-navbar class="d-md-none d-sm-block navbar-mobile">
          <SearchBar />
          <b-nav-item @click="edit">Editar mi perfil</b-nav-item>
          <b-nav-item to="/movielist">Mi Lista</b-nav-item>
          <b-nav-item @click="exit">Salir</b-nav-item>
        </b-navbar>
        <!-- menu desktop -->
        <b-navbar-nav class="ml-auto d-none d-sm-flex">
          <SearchBar />
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b-avatar
                variant="info"
                src="https://placekitten.com/300/300"
              ></b-avatar>
            </template>
            <b-dropdown-item @click="edit">Editar mi perfil</b-dropdown-item>
            <b-dropdown-item to="/movielist">Mi Lista</b-dropdown-item>
            <b-dropdown-item @click="exit">Salir</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";

import SearchBar from "@/components/SearchBar.vue";

export default {
  name: "NavBarAuth",
  components: {
    SearchBar,
  },
  methods: {
    exit() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          this.$router.push("/");
        })
        .catch(() => {
          // An error happened.
        });
    },
    edit() {
      this.$router.push("/profile");
    },
  },
};
</script>

<style scoped lang="scss">
.my-navbar {
  background-color: #2f2f2f !important;
}

.navbar-nav {
  align-items: center;
}

@media (max-width: 768px) {
  .navbar-mobile {
    display: block !important;
    list-style: none;
    .form-inline {
      display: flex;
      flex-flow: inherit !important;
      width: 100%;
    }
    .nav-link {
      color: #ffffff;
    }
  }
}
</style>
