<template>
  <b-container class="mt-4">
    <h1 class="mb-5 text-center">Próximos estrenos</h1>
    <b-row class="d-flex justify-content-center">
      <b-col
        cols="auto"
        class="col-lg-2 col-md-6"
        v-for="(movie, index) in upcomingMovies.slice(0, 5)"
        :key="index"
      >
        <img :src="getImage(movie.poster_path)" class="img-fluid" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from "vuex";
import { mapState } from "vuex";

export default {
  name: "UpcomingHome",
  methods: {
    ...mapActions(["getUpcomingMovies"]),
    getImage(image) {
      return `https://image.tmdb.org/t/p/original${image}`;
    },
  },
  mounted() {
    this.getUpcomingMovies();
  },
  computed: {
    ...mapState(["upcomingMovies"]),
  },
};
</script>

<style scoped>
</style>
