import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PopularMovies from '../views/PopularMovies.vue'
import UpcomingMovies from '../views/UpcomingMovies.vue'
import MovieList from '../views/MovieList'
import ProfileView from '../views/ProfileView'
import LoginView from '../views/LoginView'
import RegisterView from '../views/RegisterView'
import NotFound from '../views/NotFound'
import MovieInfo from '../views/MovieInfo'
import MovieInfoView from '../views/MovieInfoView'
import SearchView from '../views/SearchView'
import AboutView from '../views/AboutView'
/** firebase */


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { authRequired: false }
  },
  {
    path: '/popularmovies',
    name: 'popularmovies',
    component: PopularMovies,
    meta: { authRequired: true }
  },
  {
    path: '/upcomingmovies',
    name: 'upcomingmovies',
    component: UpcomingMovies,
    meta: { authRequired: true }
  },
  {
    path: '/movielist',
    name: 'movielist',
    component: MovieList,
    meta: { authRequired: true }
  },
  {
    path: '/movieinfo/:id',
    name: 'movieinfo',
    component: MovieInfo,
    meta: { authRequired: true }
  },
  {
    path: '/movieinfoView/:id',
    name: 'movieinfoview',
    component: MovieInfoView,
    meta: { authRequired: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: { authRequired: true }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { authRequired: false }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: { authRequired: false }
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView,
    meta: { authRequired: true }
  },
  {
    path: '/:catchAll(.*)',
    name: 'notfound',
    component: NotFound
  },

  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: { authRequired: false }

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

import { getAuth } from "firebase/auth";

router.beforeEach((to, from, next) => {

  const { currentUser } = getAuth()
  const { meta: { authRequired } } = to
  window.scrollTo(0, 0)
  console.log({ to, from })
  if (currentUser && authRequired) {
    next()
  } else if (!currentUser && authRequired) {
    next("/login")
  }
  else {
    next()
  }
})


export default router
