import { render, staticRenderFns } from "./UpcomingList.vue?vue&type=template&id=63462dcc&scoped=true&"
import script from "./UpcomingList.vue?vue&type=script&lang=js&"
export * from "./UpcomingList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63462dcc",
  null
  
)

export default component.exports