<template>
  <div>
    <NavBarAuth />
    <b-breadcrumb>
      <b-breadcrumb-item to="/popularmovies">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item active to="/movielist">Mi Lista</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container class="my-5">
      <h1>Mi lista</h1>
      <b-card
        no-body
        class="overflow-hidden"
        v-for="(movie, index) in listMovieList"
        :key="index"
      >
        <b-row no-gutters v-if="movie.mail_user == activeUser.email">
          <b-col md="2">
            <b-card-img
              :src="movie.poster_path"
              alt="Image"
              class="rounded-0 mt-3 ml-3"
            ></b-card-img>
          </b-col>
          <b-col md="7" class="mt-3 ml-3">
            <b-card-body :title="movie.original_title">
              <div class="d-flex ">
                <p class="mr-2">{{ movie.vote_average }} / 10</p>
                <b-icon-star-fill variant="warning"></b-icon-star-fill>
              </div>
            </b-card-body>
          </b-col>
          <b-col md="2" class="text-right p-2 mt-3 mb-3">
            <b-button
              class="mb-3"
              :to="{ path: '/movieinfoView/' + movie.id_movie }"
              >Más información</b-button
            >
            <b-button
              @click.prevent="activateRemoverList([movie.id])"
              to="/popularmovies"
              >Quitar de mi lista</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import NavBarAuth from "@/components/NavBarAuth.vue";

export default {
  name: "MovieList",
  components: {
    NavBarAuth,
  },
  methods: {
    ...mapActions(["activateRemoverList"]),
  },
  computed: {
    ...mapState(["listMovieList"]),
    ...mapGetters(["activeUser"]),
  },
};
</script>

<style scoped lang="scss">

</style>