<template>
<div>
     <NavBar />
<b-container class="text-center my-5">
   
    <img src="../assets/notfound.svg" height="400px" alt="notfound">
    <h1>404</h1>
    <p>¡Oops, no encontramos lo que estás buscando!</p>
    
</b-container>
<FooterView/>
</div>
</template>

<script>
 import FooterView from "@/components/FooterView.vue";
 import NavBar from "@/components/NavBar.vue";
export default {
    name: "NotFound",
    components: {        
        NavBar,
        FooterView
    },
}
</script>

<style scoped lang="scss">

h1{
    font-weight: 800;
    font-size: 5em;
}

</style>