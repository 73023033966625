<template>
  <b-container class="mt-4">
    <h1 class="mb-5">Próximos Estrenos</h1>
    <b-row>
      <b-col
        cols="auto"
        md="3"
        v-for="(movie, index) in upcomingMovies"
        :key="index"
      >
        <b-card
          :title="movie.title"
          :img-src="getImage(movie.poster_path)"
          img-alt="movie.title"
          img-top
          tag="article"
          class="mb-4 mx-2"
        >
          <div class="d-flex">
            <p class="mr-2">{{ movie.vote_average }} / 10</p>
            <b-icon-star-fill variant="warning"></b-icon-star-fill>
          </div>
          <b-button :href="`/movieinfo/${movie.id}`">Ver más detalles</b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from "vuex";
import { mapState } from "vuex";

export default {
  name: "UpcomingList",
  methods: {
    ...mapActions(["getUpcomingMovies"]),
    getImage(image) {
      return `https://image.tmdb.org/t/p/original${image}`;
    },
  },
  mounted() {
    this.getUpcomingMovies();
  },
  computed: {
    ...mapState(["upcomingMovies"]),
  },
};
</script>

<style scoped>
</style>
