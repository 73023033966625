<template>
  <div class="home">
    <NavBarAuth />
    <b-breadcrumb>
      <b-breadcrumb-item to="/popularmovies">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item>Resultado de la Busqueda</b-breadcrumb-item>
    </b-breadcrumb>
    <SearchList />
  </div>
</template>

<script>
// @ is an alias to /src
import SearchList from "@/components/SearchList.vue";
import NavBarAuth from "@/components/NavBarAuth.vue";

// import { mapActions } from "vuex";

export default {
  name: "SearchView",
  components: {
    SearchList,
    NavBarAuth,
  },
  methods: {
    // ...mapActions(["addPopularMovies"]),
    popular() {
      this.$router.push("/popularmovies");
    },
  },
  mounted() {
    // this.addPopularMovies()
  },
};
</script>
