<template>
<div>
  <NavBar />
  <b-breadcrumb>
    <b-breadcrumb-item to="/">
    <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
    </b-breadcrumb-item>
    <b-breadcrumb-item active to="/register">Registro</b-breadcrumb-item>
  </b-breadcrumb>
  <b-container class="d-flex justify-content-center">
    <b-card
    style="max-width: 25rem;"
    class="mb-2 my-5 mx-2 text-center"
    >
    <h1 class="my-4">Registra tus datos</h1>
    <div role="group">
      <b-form-input id="input-live"
        v-model="user.nick"
        :state="nickState"
        type="text"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Ingresa tu Nick"
        trim
      >
      </b-form-input>

      <b-form-invalid-feedback id="input-live-feedback">
        Ingresa al menos 4 letras
      </b-form-invalid-feedback>
    </div>

    <div role="group">
      <label for="input-live"></label>    

      <b-form-input
        id="input-live"
        v-model="user.email"
        type="email"
        :state="emailState"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Ingresa tu email"
        trim
      >
      </b-form-input>

      <b-form-invalid-feedback id="input-live-feedback">
        Ingresa al menos 8 letras
      </b-form-invalid-feedback>   
    </div>

    <div role="group">
      <label for="input-live"></label>
      <b-form-input
        id="input-live"
        v-model="user.password"
        type="text"
        :state="passwordState"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="Ingresa tu password"
        trim
      >
      </b-form-input>

      <b-form-invalid-feedback id="input-live-feedback">
        Ingresa al menos 8 letras
      </b-form-invalid-feedback>
    </div>
      <b-button class="my-4" @click="register">Ingresar</b-button>
    </b-card> 
  </b-container>  
</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore"; 
import { mapState } from 'vuex';

export default {
    name: "RegisterView",
    components: {       
         NavBar,     
     },
    computed: {
      emailState() {
        return this.user.email.length > 8 ? true : false
      },
      passwordState() {
        return this.user.password.length > 7 ? true : false
      },
      nickState() {
        return this.user.nick.length > 3 ? true : false
      },
      ...mapState(["myFirestore"]),

    },
    data() {
      return {       
        user:{
          email: "", 
          password: "",
          nick:"", 
        }
      };
      
    },
      methods: {       
    async register() {
      try {
        const { email, password, nick} = this.user;
        const auth = getAuth();
        await createUserWithEmailAndPassword(auth, email, password);
        await addDoc(collection(this.myFirestore, "nickuser"), {
            mailuser : email,
            nick_user : nick,
        }),
          this.$router.push("/popularmovies");
      } catch (error) {
        alert("Este usuario ya existe ingrese Nuevo correo");
      }
    },
  },
} 

</script>
<style scoped>

</style>